import React from 'react'

export default () => {
  return (
    <svg width="30" height="31" viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg">
      <title>Go to skype</title>
      <path
        d="M20.77 15.232c-.641-.429-1.427-.798-2.335-1.097a31.138 31.138 0 00-3.014-.812 53.485 53.485 0 01-1.888-.475 5.908 5.908 0 01-1.097-.44 2.455 2.455 0 01-.813-.634 1.261 1.261 0 01-.272-.817c0-.521.276-.961.845-1.345.59-.399 1.385-.6 2.363-.6 1.052 0 1.818.182 2.277.54.472.37.883.893 1.224 1.56.293.52.558.882.813 1.114.275.249.67.377 1.176.377.555 0 1.027-.203 1.4-.602.371-.397.56-.853.56-1.354 0-.521-.144-1.06-.427-1.602-.28-.535-.726-1.05-1.326-1.532-.595-.48-1.353-.869-2.253-1.157-.893-.285-1.965-.43-3.182-.43-1.524 0-2.87.218-4.002.649-1.147.436-2.037 1.072-2.647 1.89-.615.825-.926 1.78-.926 2.84 0 1.112.297 2.059.882 2.812.577.743 1.366 1.337 2.343 1.764.957.418 2.159.79 3.575 1.101 1.04.224 1.882.438 2.502.637.594.191 1.084.469 1.456.826.354.341.526.776.526 1.33 0 .701-.33 1.274-1.01 1.75-.695.49-1.619.737-2.746.737-.82 0-1.486-.122-1.981-.363-.491-.24-.876-.545-1.144-.91a7.153 7.153 0 01-.783-1.429c-.217-.522-.486-.928-.8-1.201a1.775 1.775 0 00-1.2-.43c-.572 0-1.05.183-1.424.544-.378.365-.57.81-.57 1.325 0 .822.294 1.676.874 2.535a6.46 6.46 0 002.245 2.05c1.282.7 2.925 1.054 4.883 1.054 1.632 0 3.066-.26 4.262-.77 1.208-.518 2.14-1.245 2.77-2.163a5.432 5.432 0 00.956-3.132c0-.967-.187-1.798-.555-2.471a4.736 4.736 0 00-1.538-1.67zm.378 15.329a7.702 7.702 0 01-3.738-.966c-.81.155-1.644.236-2.498.236-7.531 0-13.638-6.283-13.638-14.032 0-.967.096-1.91.277-2.822A8.278 8.278 0 01.464 8.861c0-4.494 3.54-8.137 7.908-8.137 1.548 0 2.99.458 4.209 1.248a13.359 13.359 0 012.331-.205c7.533 0 13.638 6.283 13.638 14.032 0 1.033-.11 2.04-.315 3.01a8.297 8.297 0 01.822 3.615c0 4.493-3.54 8.137-7.909 8.137z"
        fill="#fff"
      />
    </svg>
  )
}
