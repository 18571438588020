import React, { useEffect, useState } from 'react'
import styles from './style.module.sass'

export default () => {
  const [visible, setVisible] = useState(false)
  const [submitted, setSubmitted] = useState(false)

  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [state, setState] = useState('')

  const closeHandler = () => {
    setVisible(false)
    setSubmitted(false)
    setName('')
    setEmail('')
    setState('')
    window.history.pushState('', document.title, window.location.pathname)
  }

  const submitHandler = e => {
    e.preventDefault()
    setSubmitted(true)

    try {
      window.braze.logCustomEvent('trt-waitlist', { name, email, state })
      window.analytics.track('trt-waitlist', { name, email, state })
    } catch {}
  }

  const hashChangeHandler = () => {
    const hash = window.location.hash

    if (hash === '#modal-trt-waitlist') {
      setVisible(true)
    } else {
      setVisible(false)
    }
  }

  useEffect(() => {
    window.addEventListener('hashchange', hashChangeHandler)
    return () => window.removeEventListener('hashchange', hashChangeHandler)
  }, [])

  return (
    <>
      {visible && (
        <div className={styles.modal}>
          <div className={styles.background} onClick={closeHandler} />
          <div className={styles.inside}>
            <div className={styles.close} onClick={closeHandler} />
            <h2 className={styles.title}>TRT+ Waitlist</h2>

            {submitted ? (
              <p className={styles.infoSubmitted}>
                Thank you. <br />
                <br />
                We will send a notification when the product is released
              </p>
            ) : (
              <>
                <p className={styles.info}>
                  Ben Greefield Life listeners, we are working hard to bring this product to you. We are currently
                  finalizing key details to ensure this product experience satisfies your needs. Please sign up for this
                  waitlist to be notified once the product is released.
                </p>
                <form className={styles.form} onSubmit={submitHandler}>
                  <input
                    required
                    className={styles.input}
                    type="text"
                    placeholder="What is your name?"
                    value={name}
                    onChange={e => setName(e.target.value)}
                  />
                  <input
                    required
                    className={styles.input}
                    type="email"
                    placeholder="What is your email?"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                  />
                  <input
                    required
                    className={styles.input}
                    type="text"
                    placeholder="What state do you currently live in?"
                    value={state}
                    onChange={e => setState(e.target.value)}
                  />
                  <input className={styles.submit} type="submit" value="Submit" />
                </form>
              </>
            )}
          </div>
        </div>
      )}
    </>
  )
}
