import React from 'react'
import { Link } from 'gatsby'
import cx from 'classnames'
import styles from './style.module.css'

export default props => {
  const { url, button, classes, btnType, children, gatsbyLink = false, handler = false, ...rest } = props

  if (gatsbyLink) {
    return (
      <Link className={cx(styles.block, classes, styles[btnType])} to={url}>
        {children}
      </Link>
    )
  }

  if (button) {
    return (
      <button className={cx(styles.block, classes, styles[btnType])} {...rest}>
        {children}
      </button>
    )
  }

  return (
    <a className={cx(styles.block, classes, styles[btnType])} href={url} onClick={e => handler && handler(e)} {...rest}>
      {children}
    </a>
  )
}
