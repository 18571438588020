import React, { useEffect } from 'react'
import cx from 'classnames'
import Button from '../Button'
import { urlWithSearchParamsHandler } from '../../../../utils/format'
import styles from './style.module.sass'

const GetStartedButton = ({ className, url }) => {
  return (
    <Button btnType="full" classes={cx(styles.button, className)} url={url} handler={urlWithSearchParamsHandler}>
      GET STARTED NOW
    </Button>
  )
}

export default GetStartedButton
