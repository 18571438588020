import React, { useEffect, useState } from 'react'
import { prepareParagraph } from '../../../utils/format'
import styles from './style.module.sass'

export default ({ data: { modalId, title, body } }) => {
  const key = `welcome-modal-${modalId}`
  const [visible, setVisible] = useState(false)

  const closeHandler = () => {
    setVisible(false)
  }

  useEffect(() => {
    if (!localStorage.getItem(key)) {
      localStorage.setItem(key, true)
      setVisible(true)
    }
  }, [])

  return (
    <>
      {visible && (
        <div className={styles.modal}>
          <div className={styles.background} onClick={closeHandler} />
          <div className={styles.inside}>
            <div className={styles.close} onClick={closeHandler} />
            {title && <h2 className={styles.title}>{title}</h2>}
            <p className={styles.info}>{prepareParagraph(body)}</p>
          </div>
        </div>
      )}
    </>
  )
}
