import React from 'react'

export default () => {
  return (
    <svg width="15" height="32" viewBox="0 0 15 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <title>Go to facebook</title>
      <path
        d="M14.266 15.58H9.93v15.448H3.51V15.58H.456v-5.46H3.51V6.59c0-2.53 1.202-6.482 6.481-6.482l4.76.019v5.3h-3.455c-.562 0-1.361.28-1.361 1.487v3.208h4.893l-.561 5.46z"
        fill="#fff"
      />
    </svg>
  )
}
