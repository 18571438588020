import React from 'react'
import { Link } from 'gatsby'
import cx from 'classnames'
import Container from '../UI/Container'
import GetStartedButton from '../UI/GetStartedButton'
import { imageAutoFormat, sanityImageUrl } from '../../../utils/format'
import styles from './style.module.sass'

export default ({
  hero,
  classes,
  contacts,
  heroBodyStyle,
  heroBodyClasses,
  customHero,
  getStartedNowUrl,
  heroContainerId,
}) => {
  return (
    <Container classes={styles.outer} id={heroContainerId}>
      <header
        className={cx(styles.hero, classes)}
        style={{
          background: `url(${imageAutoFormat(sanityImageUrl(hero.image))}) center ${
            hero.isBackgroundCenter ? 'center' : 'right'
          }`,
        }}
      >
        <div>
          <div className={styles.shadow} />
          <div className={styles.gradient} />
        </div>

        <div
          className={cx(styles.body, heroBodyClasses, {
            [styles.center]: hero.isCenter,
            [styles.left]: !hero.isCenter,
          })}
          style={heroBodyStyle}
        >
          {!customHero && (
            <div
              className={cx(styles.heroCopy, {
                [styles.withButton]: hero.isShowGetStartedNowUrl,
              })}
            >
              {!!hero.mainTitle && (
                <h2 className={styles.mainTitle} dangerouslySetInnerHTML={{ __html: hero.mainTitle }} />
              )}
              <h1>
                <div dangerouslySetInnerHTML={{ __html: hero.title }} />
              </h1>
              <div className={styles.heroContent}>{hero.body}</div>
              {hero.isShowGetStartedNowUrl && getStartedNowUrl && (
                <div className={styles.getStartedNowButtonContainer}>
                  <GetStartedButton className={styles.getStartedNowButton} url={getStartedNowUrl} />
                </div>
              )}
            </div>
          )}

          {customHero}

          {contacts && (
            <div className={styles.contactUs}>
              <div className={styles.header}>Contact Us</div>
              {contacts.map(contact => (
                <div className={styles.row}>
                  {contact.label && <div className={styles.label}>{contact.label}:</div>}
                  {contact.email && <div className={styles.text}>{contact.email}</div>}
                </div>
              ))}
            </div>
          )}
        </div>
      </header>
    </Container>
  )
}
