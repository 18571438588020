import React from 'react'

export default () => {
  return (
    <svg width="31" height="32" viewBox="0 0 31 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <title>Go to linkedin</title>
      <path
        d="M7.541 31.546H.881V8.236h6.66v23.31zM4.418 6.807a3.12 3.12 0 01-3.12-3.123A3.123 3.123 0 014.417.56a3.124 3.124 0 010 6.247zm26.434 24.74h-6.66V17.133c0-1.689-.483-2.87-2.558-2.87-3.44 0-4.103 2.87-4.103 2.87v14.412h-6.66V8.236h6.66v2.228a11.14 11.14 0 016.66-2.225c2.158 0 6.66 1.292 6.66 9.098v14.21z"
        fill="#fff"
      />
    </svg>
  )
}
